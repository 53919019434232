<template>
 <div class="flex items-center">
    <div class="borderline h1 border-gray-300 flex-grow"></div>
    <p class="text-center text-gray-400 mx-3">
      {{ text }}
    </p>
    <div class="borderline h1 border-gray-300 flex-grow"></div>
 </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: 'TextSeparator',
  props: ['text']
})
</script>

<style scoped>
.borderline {
  border-width: 1px;
}
</style>