<template>
   <B2b3_4Layout>
    <Login />
  </B2b3_4Layout>
</template>

<script>
import { defineComponent } from "vue";
import B2b3_4Layout from "../layout/B2b3_4Layout.vue";
import Login from "../components/b2b4/Login.vue";

export default defineComponent({
  name: 'B2b4',
  components: {
    B2b3_4Layout,
    Login
  }
})
</script>

<style>

</style>