<template>
  <label class="ctn">
    <input type="checkbox" checked="checked" />
    <span class="checkmark bg-tertiary rounded-md"></span>
  </label>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
});
</script>

<style scoped>
/* The ctn */
.ctn {
  display: block;
  position: relative;
  margin-right: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ctn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  padding: 4px;
  border-radius: 5px;
  background-color: #4cbfde;
}

/* On mouse-over, add a grey background color 
.ctn:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.ctn input:checked ~ .checkmark {
  /* background-color: #E5F1FF; */
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ctn input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ctn .checkmark:after {
  left: 10px;
  top: 6px;
  width: 6px;
  height: 11px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>