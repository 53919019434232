<template>
  <div>
    <h1 class="text1">Connectez-vous</h1>
    <p class="text2">Saisissez votre identifiant et mot de passe !</p>
    <form class="grid grid-cols-4 gap-3">
      <input placeholder="Adresse email" class="col-span-4" />
      <input placeholder="Mot de passe" class="col-span-4" />

      <div
        class="col-span-4 flex justify-between items-center"
        style="height: 34px"
      >
        <div class="flex items-start">
          <Checkbox />
          <p class="text3" style="margin-top: 2px">Rester connecté</p>
        </div>
        <div class="flex items-start">
          <div style="height: 16px; object-fit: contain">
            <img
              src="../../assets/lock.png"
              style="width: 12.7px; height: 15.3px"
            />
          </div>
          <p class="text4" style="margin-left: 8px">Mot de passe oublié</p>
        </div>
      </div>

      <button class="col-span-4 submit-btn">Connectez-vous !</button>

      <div class="col-span-4">
        <TextSeparator text="Ou" />
      </div>

      <button
        class="col-span-4 p-3 border-2 border-gray-300 rounded bg-transparent flex justify-center items-center"
      >
        <img
          src="../../assets/001-google.png"
          style="width: 24px; height: 25px; margin: 0 8px 0 0"
        />
        <p class="Se-connecter-avec-Google">Se connecter avec Google</p>
      </button>

      <!--router-link
        :to="{ name: 'inscription' }"
        class="col-span-4 ou-connectez-vous"
        ><span class="text-style-1">ou</span> inscrivez-vous
      </router-link-->
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Checkbox from "../public/Checkbox.vue";
import TextSeparator from "../public/TextSeparator.vue";
export default defineComponent({
  name: "Login",
  components: {
    Checkbox,
    TextSeparator,
  },
});
</script>

<style scoped>
input {
  height: 50px;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  background-color: #ffffff;
  padding-left: 17px;
}
input::placeholder {
  height: 16px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}
div:nth-child(4) input:nth-child(2)::placeholder {
  color: #363660;
}

.text1 {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}

.text2 {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  margin-bottom: 24px;
}

.text3 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}

.text4 {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #4cbfde;
}

.submit-btn {
  padding: 16px 94px 16px 93px;
  border-radius: 4px;
  background-color: #e94a73;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.ou-connectez-vous {
  margin: 16px 113px 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4cbfde;
}
.ou-connectez-vous .text-style-1 {
  color: #363660;
}

.Se-connecter-avec-Google {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
</style>