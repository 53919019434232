<template>
  <div class="w-screen h-screen grid grid-cols-1 lg:grid-cols-3">
    <router-link :to="{ name: 'accueil' }" class="logo">
      <img src="../assets/images/RVB_Exval-favicon-color-1-2.svg" alt="logo" />
    </router-link>

    <div
      style="background-color: #f6f7fb"
      class="col-span-2 flex justify-center items-center"
    >
      <div class="lg:w-2/5">
        <slot />
      </div>
    </div>

    <div class="flex flex-col justify-center bg-primary relative">
      <div class="flex-1 flex items-center">
        <img
          src="../assets/images/illustration-4-1-1.svg"
          alt="illustration-4"
          class="relative illustration lg:right-20"
        />
      </div>

      <div class="info-ctn flex">
        <div class="text1 infos-bulle">
          Puis-je vous aider, que <br />
          cherchez-vous ?
        </div>
        <div class="elipse">
          <img src="../assets/message-square.svg" alt="message-square" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "B2b3_4Layout",
});
</script>

<style scoped>
.logo {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 48px;
  height: 48px;
}

.illustration {
  width: 494px;
  height: 475px;
}

.infos-bulle {
  width: 200px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
  border-radius: 10px;
  background-color: #ffffff;
}

.text1 {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}

.info-ctn {
  position: absolute;
  right: 48px;
  bottom: 40px;
}

.elipse {
  width: 48px;
  height: 48px;
  margin: 5px 0 4px 10px;
  padding: 12px;
  background-color: #4cbfde;
  border-radius: 100%;
}
</style>